import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import data from "../../JSON/gameData.json";
import { Modal, Spinner } from 'react-bootstrap';
import AdComponent from '../AdComponent/AdComponent';

function GameIframe(props) {
  let [timer, setTimer] = useState(5);
  const { gameId } = useParams();
  const [loader, setLoader] = useState(true)
  const [game, setgame] = useState({})

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  useEffect(() => {
    if (gameId) {
      let loadGame = data.find(x => x.shortUrl == gameId)
      if (loadGame) {
        setgame({ ...loadGame })
        window.gtag("event", "game", {
          event_category: "game",
          event_label: loadGame?.title
        });
      }
    }
  }, [gameId])

  useEffect(() => {
    // setShow(true);
    setTimeout(() => {
      setLoader(false)
    }, 2000);
  }, [])

  useEffect(() => {
    var timerID = setInterval(() => {
      if (timer > 0) {
        timer -= 1;
        setTimer(timer);
      } else {
        clearInterval(timerID)
      }
    }, 1000);

    return () => clearInterval(timerID)
  }, []);

  return (
    <>
      {
        loader && <div className='loader'>
          <Spinner animation="border" variant='light' role="status"></Spinner>
        </div>
      }
      <div className="bgContent-iframe p-0">
        <div className="iframe-container">
          {
            game.id && <iframe src={game.gameUrl}></iframe>
          }

        </div>
      </div>
    </>
  )
}

export default GameIframe