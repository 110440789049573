import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/images/logo.png";
import { Link, NavLink } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

import home from "../../assets/images/home.png";
import aboutus from "../../assets/images/aboutus.png";
import contact from "../../assets/images/contact.png";
import privacy from "../../assets/images/privacy.png";

function Header(props) {
  const [show, setShow] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logout = () => {
    removeCookie('user')
  }
  useEffect(() => {
    if (props.name) {
      window.gtag("event", "page", {
        event_category: "page",
        event_label: props.name
      });
    }
  }, [])

  return (
    <>
      <div className="header">
        <div className="main-container">
          <div className="row m-0">
            <div className="col-6 col-md-2">
              <a href="/">
                <img src={logo} alt="" style={{ width: "110%" }} />
              </a>
            </div>
            <div className="col-6 col-md-10 d-flex justify-content-end align-items-center ">
              <div className="menu justify-content-end align-items-center d-lg-flex d-none">

                {
                  cookies.user ? <>
                    <NavLink to="/gameList">GAME LIST</NavLink>
                    <NavLink to="/addgame">ADD GAME</NavLink>
                  </> : <>  <NavLink to="/"><img src={home} className="headericonimg" alt="" /> Home</NavLink>
                    <span onClick={() => window.location.reload()}><NavLink to="/aboutus"><img src={aboutus} className="headericonimg" alt="" />About Us</NavLink></span>
                    <span onClick={() => window.location.reload()}><NavLink to="/contactus"><img src={contact} className="headericonimg" alt="" />Contact Us</NavLink></span>
                    <span onClick={() => window.location.reload()}><NavLink to="/privacy-policy"><img src={privacy} className="headericonimg" alt="" />Privacy Policy</NavLink></span>
                  </>
                }

              </div>
              <div>

              </div>
              {
                cookies.user && <div>
                  <button onClick={logout} style={{ padding: '5px 15px' }}>Logout</button>
                </div>
              }
              <div
                className="d-flex d-lg-none justify-content-end align-items-center h-100"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <path
                    fill="#FFFFFF"
                    fillRule="evenodd"
                    d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} className="bg-dark">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="menu justify-content-center align-items-center">
            <span onClick={() => window.location.reload()}><NavLink className="d-block mb-3" to="/"><img src={home} className="headericonimg" alt="" />
              Home
            </NavLink></span>
            <span onClick={() => window.location.reload()}><NavLink className="d-block mb-3" to="/aboutus"><img src={aboutus} className="headericonimg" alt="" />
              About Us
            </NavLink></span>
            <span onClick={() => window.location.reload()}><NavLink className="d-block mb-3" to="/contactus"><img src={contact} className="headericonimg" alt="" />
              Contact Us
            </NavLink></span>
            <span onClick={() => window.location.reload()}><NavLink className="d-block" to="/privacy-policy"><img src={privacy} className="headericonimg" alt="" />
              Privacy Policy
            </NavLink></span>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Header;
