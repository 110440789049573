import React, { useState } from 'react'
import ViewMaster from '../../Components/ViewMaster/ViewMaster'
import './AddGame.css'
import { useForm } from 'react-hook-form';

function AddGame() {
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState([]);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddButtonClick = () => {
    if (inputValue.trim() !== '') {
      setData([...data, inputValue.trim()]);
      setInputValue('');
    }
  };

  const onsubmit = (e) => {
    setData([]);
    reset()
  };

  const handleDeleteTag = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
  };


  return (
    <>
      <div className='bgContent Add_Game_Main'>
        <form className='Add_Game_Border mt-3'>
          <div>
            <input type="text" name='title' className='input' placeholder='Title'{...register("title", { required: true })} />
            {errors.title && <span className='errorMessage'>Title is required</span>}
          </div>
          <div className='d-flex'>
            <div className="flex align-items-center mt-3">
              <input type='checkbox' name="isIframe" id="isIframe"  {...register("isIframe")} />
              <label for="flexRadioDefault1" className="ms-2 text-white">IsIframe</label><br />
            </div>
          </div>
          <div className='mt-3'>
            <textarea id="description1" className='input' name="description1" style={{ minHeight: '70px' }} rows="2" cols="50" placeholder='description1'></textarea>
          </div>
          <div className='mt-3'>
            <textarea id="description2" className='input' name="description2" style={{ minHeight: '70px' }} rows="2" cols="50" placeholder='description2'></textarea>
          </div>
          <div className='mt-3'>
            <textarea id="description3" className='input' name="description3" style={{ minHeight: '70px' }} rows="2" cols="50" placeholder='description3'></textarea>
          </div>
          <div className='mt-3'>
            <textarea id="description4" className='input' name="description4" style={{ minHeight: '70px' }} rows="2" cols="50" placeholder='description4'></textarea>
          </div>
          <div className='mt-3'>
            <textarea id="description5" className='input' name="description5" style={{ minHeight: '70px' }} rows="2" cols="50" placeholder='description5'></textarea>
          </div>
          <div className='mt-3'>
            <div className='d-flex'>
              <input type="text" className='input' value={inputValue} onChange={handleInputChange} placeholder="Enter Tags" />
              <svg onClick={handleAddButtonClick} className='ms-2' style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="40px" fill='white' height="40px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z" /></svg>
            </div>
            {
              <div className="d-flex flex-wrap gap-1 mt-3 tags">
                {data.map((tagName, index) => (
                  <div key={index} className="tag text-nowrap d-flex align-items-center">
                    {tagName}
                    <svg
                      onClick={() => handleDeleteTag(index)}
                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                      viewBox="0 0 24 24"
                      width="20px"
                      height="20px"
                      fill="red"
                    >
                      <path d="M 10.707031 12 L 15.853516 6.8535156 L 14.439453 5.4394531 L 9.2929688 10.585938 L 4.1464844 5.4394531 L 2.7324219 6.8535156 L 7.8789062 12 L 2.7324219 17.146484 L 4.1464844 18.560547 L 9.2929688 13.414062 L 14.439453 18.560547 L 15.853516 17.146484 L 10.707031 12 z" />
                    </svg>
                  </div>
                ))}
              </div>
            }
          </div>
          <div className='mt-3'>
            <button type='submit' className='input ADD_GAME_BUTTON' onClick={handleSubmit(onsubmit)} style={{ width: '70px', backgroundColor: 'blue' }}>Save</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ViewMaster(AddGame)