import React, { useEffect } from 'react'
import ViewMaster from '../../Components/ViewMaster/ViewMaster'

function Contact() {
  useEffect(() => {
    document.title = "Crazy Play Game | Contact Us"
  }, [])
  return (
    <>
      <div>
        <div className="bgContent">
          <div className="main-container py-4 text-white fs-16">
            <div className="mb-4 pb-3">
              <h4 className="DescriptionHeading"> Contact US </h4>
              <p> Contact@crazyplaygame.com</p>
            </div>
            <div className="mt-4">
              <h4 className="DescriptionHeading"> Copy Right Issue </h4>
              <p> At Crazy Play Game, we are committed to respecting intellectual property rights and have made efforts to conform with them on our platform. Some of these games were developed by us while others are licensed from known copyright holders. In some cases, there is lack of copyright information due to general internet use and thus we make sure that they comply with certain prerequisites.</p>
              <p> No change is made in the source code of the games thereby preserving credits, brand names and website links unaltered.If you believe that your copyright or other IPR has been violated on Crazy Play Game’ sites, please contact Contact@crazyplaygame.com with:</p>
              {/* <p> We do not change anything in the source code of the games. For example, credit holders, brand names, or references to websites remain unchanged. If any copyright or other IPR that you may have is possibly being infringed by/on Crazy Play Game’ sites, please inform us immediately, thereby providing us with the following:</p> */}
              <p>(i). Either your electronic or physical signature or that of your authorized agent.</p>

              <p>(ii). An explanation of the infringed IP Right and the infringing activity.</p>

              <p>(iii). The specific location of the unauthorized material (e.g., URL).;</p>

              <p>(iv). A copy of the license permitting its use (where applicable).</p>

              <p>(v). Identify where precisely you think this breach occurred.</p>

              <p>(vi). Your name, contact details, and good faith belief that no permission was granted for such purposes.</p>

              <p>Send above details at Contact@crazyplaygame.com. We promise to examine any issues raised within seven days after they are reported.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewMaster(Contact)