import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './custom.css';
import Home from './Pages/Home/Home';
import Game from './Components/Game/Game';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Privacy from './Pages/Privacy/Privacy';
import GameIframe from './Components/GameIframe/GameIframe';
import Login from './Pages/Login/Login';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import Header from './Components/Header/Header';
import ViewMaster from './Components/ViewMaster/ViewMaster';
import GameList from './Pages/GameList/GameList';
import AddGame from './Pages/AddGame/AddGame';

function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path='/' element={<Home name="Home" />} />
        <Route path='/game' element={<Game />}>
          <Route path=':gameId' element={<Game />} />
        </Route>
        <Route path='/aboutus' element={<About name="aboutus" />} />
        <Route path='/contactus' element={<Contact name="contactus" />} />
        <Route path='/privacy-policy' element={<Privacy name="privacy-policy" />} />
        <Route path='/games' element={<GameIframe name="Games" />}>
          <Route path=':gameId' element={<GameIframe name="Games" />} />
        </Route>

        {/* {cookies.user ?
          <>
            <Route path='/login' element={<Navigate to='/gameList' />} />
            <Route path='/gameList' element={<GameList />} />
            <Route path='/addGame' element={<AddGame />} />
            <Route path='' element={<Header />}></Route>
          </> :
          <>
            <Route path='/gameList' element={<Navigate to='/login' />} />
            <Route path='/login' element={<Login />} />
          </>
        } */}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
