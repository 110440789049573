import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import "./Game.css";
import data from "../../JSON/gameData.json";
import GameCard from "../GameCard/GameCard";
import ViewMaster from "../ViewMaster/ViewMaster";
import { FaArrowCircleUp } from "react-icons/fa";
import { scrollToTop } from "../../Custom/custom";
import AdComponent from '../AdComponent/AdComponent';
import { Modal, Spinner } from 'react-bootstrap';

function Game() {
  const { gameId } = useParams();
  const [game, setgame] = useState({})
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);


  useEffect(() => {
    if (gameId) {
      let loadGame = data.find(x => x.shortUrl == gameId)
      if (loadGame) {
        setgame({ ...loadGame })
        document.title = loadGame.title + " | Crazy Play Game";
        window.gtag("event", "game", {
          event_category: "game",
          event_label: loadGame?.title
        });
      }
    }
  }, [gameId])
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [gameId])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    // setShow(true);
  }, [])

  return (
    <>
      <div>
        <div className='scrollTop cursor-pointer' onClick={() => scrollToTop()}><FaArrowCircleUp size={30} color='#fff' /></div>
        <div className="bgContent pt-4">
          <div className="my-container">
            <div className="common-game-bg-container" style={{ width: '100%', height: '400px', minHeight: "400px", height: 'auto' }}>
              {/* <div className="common-game-bg"></div> */}
              <img className="common-game-bg" src={game.logo ? require(`../../assets/images/${game?.logo}`) : ''} alt={"Crazy Play Game - " + game?.title} />
              <div className="common-game-bg-overlay-banner">
                <div className="center-content">
                  <img src={game.logo ? require(`../../assets/images/${game?.logo}`) : ''} alt={"Crazy Play Game - " + game?.title} />
                  <a href={`/games/${gameId}`}><button className="mt-4 animatebutton">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="text-white inline-block me-1.5 mb-1" height="23" width="23" xmlns="http://www.w3.org/2000/svg"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z"></path></svg>
                    <b class="text-[#ffff] text-xl ml-1">PLAY NOW</b></button>
                  </a>
                </div>
              </div>
            </div>
            <div className="fs-18 text-white mt-5 description">
              <p className="m-0 mb-3">{game.description1}</p>

              <p className="m-0 mb-3">{game.description2}</p>

              <p className="m-0 mb-3">{game.description3}</p>

              <p className="m-0 mb-3">{game.description4}</p>

              <p className="m-0 ">{game.description5}</p>
            </div>
            {
              <div className="d-flex flex-wrap gap-1 tags">
                {
                  game.tags?.map((tagName, index) => {
                    return <div key={index} className="tag text-nowrap">{tagName}</div>
                  })
                }
              </div>
            }
            <div>
              <div className='row m-0 g-1 mt-4'>
                {
                  data.map((element, index) => {
                    return <Fragment key={index}>
                      <div key={index} className='col-6 col-sm-4 col-lg-2 p-2'>
                        <GameCard data={element} />
                      </div>
                    </Fragment>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default ViewMaster(Game);
