export const scrollFunction = () => {
    let scrollTopButton = document.querySelector('.scrollTop')
    if(scrollTopButton){
        if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
            scrollTopButton.style.display = "block";
        } else {
            scrollTopButton.style.display = "none";
        }
    }
}
window.addEventListener('scroll' , () => {
    scrollFunction()
})
export const scrollToTop = () => {
    window.scrollTo({ top: 0 });
}