import React, { useEffect } from 'react'
import ViewMaster from '../../Components/ViewMaster/ViewMaster'

function Privacy() {
  useEffect(() => {
    document.title = "Crazy Play Game | Privacy Policy & Terms of Use"
  }, [])
  return (
    <>
      <div>
        <div className="bgContent">
          <div className="main-container py-4 text-white fs-16">
            <div>
              <h4 className="DescriptionHeading">Privacy Policy & Terms of Use</h4>
              <div>
                <h4>(i). Introduction</h4>
                <p className='ms-4'>Hello, Crazy Play Game. This Privacy Policy and the Terms of Use govern your visit to our website, Crazy Play Game, and other services related to it that we provide.</p>
                <p className='ms-4'>In case you make use of any part of our website, you should know that you have implicitly accepted this policy. It’s important for you to go through this document carefully so as to understand how we manage, guard and utilize information from you.</p>
              </div>
              <div>
                <h4>(ii). Data Collection</h4>
                <p className='ms-4'>We have strict policies against gathering user information or data.</p>
              </div>
              <div>
                <h4>(iii). Use of Data</h4>
                <div className='ms-4'>
                  <p>This is the purpose for which we practice:</p>
                  <ul>
                    <li>For effective running and operation our site.</li>
                    <li>For managing your account and affiliating you in partner program’s participation.</li>
                    <li>Regarding updates about accounts, inquiries and other issues connected with it communicating with us.</li>
                    <li>Making user experience better and developing additional features to our web site.</li>
                    <li>To comply with legal requirements.</li>
                  </ul>
                </div>
              </div>
              <div>
                <h4>(iv). Third-Party Services</h4>
                <p className='ms-4'>Even though no third party is allowed to access or process data directly however, third-party payments processors are used by us in order to ensure secure transactions. For more detail please refer their privacy policies where applicable.</p>
              </div>
              <div>
                <h4>(v). Location of Data Processing</h4>
                <p className='ms-4'>It is based within the United States where your data gets processed at.</p>
              </div>
              <div>
                <h4>(vi). Children's Privacy</h4>
                <p className='ms-4'>However much it may be targeted towards children, our website has not been created for individuals who are less than 13 years old. We don’t knowingly obtain personal information provided by children under such age.</p>
              </div>
              <div>
                <h4>(vii). Security Measures</h4>
                <p className='ms-4'>We implement reasonable security measures to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction.However, please be aware that no method of transmission over the internet or storage system is entirely secure. Exercise caution when sharing information online.</p>
              </div>
              <p>Our platform does not involve any gambling activities or the collection of real-world currency. All in-game currency is virtual and holds no monetary value.</p>
              <p>Our games are designed purely for entertainment purposes, and we do not request any payments from users.</p>
              <p>All the games for a fun and entatement perpose we do not ask to do any payment collection from user side.</p>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default ViewMaster(Privacy)